import React from 'react';
import ReactDOM from 'react-dom';

// App components

import Theme from './utils/theme';
import AuthStore from './utils/context/authStore';
import Routes from './utils/router';
import ErrorBoundary from './views/layout/errorBoundary';

import './theme/style.scss';

ReactDOM.render(
    <React.Fragment>
        <ErrorBoundary>
            <Theme>
                <AuthStore>
                    <Routes />
                </AuthStore>
            </Theme>
        </ErrorBoundary>
    </React.Fragment>,
    document.getElementById('root'),
);
