import React, { useState, createContext, useContext, useEffect } from 'react';

// Dependencies
import Container from '../../../layout/container';
import { CircularProgress } from '@mui/material';
import { logError } from '../../../../utils/helpers';
import { makeLocalEndpointRequest } from '../../../../utils/endpoints';

// Context
const Context = createContext({});
export const PageContext = () => useContext(Context);

// Components
import Header from './components/header';
import List from './components/list';
import CreateDialog from './components/createDialog';

const Component = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadData = async () => {
        try {
            const res = await makeLocalEndpointRequest(`/api/deals/list`, {}, 'GET');
            setData(res);
            setLoading(false);
        } catch (err) {
            await logError(`LOAD_DEALS`, err);
        }
    };

    const ContextProps = {
        data,
        setData,
        loading,
        setLoading,
    };

    useEffect(() => {
        loadData();
    }, []);

    if (loading === true) {
        return (
            <Container title="Deals" classNames="page-deals-list" isAlternateBackground={true}>
                <div className="loading-container">
                    <CircularProgress />
                </div>
            </Container>
        );
    }

    return (
        <Container title="Deals" classNames="page-deals-list" isAlternateBackground={true}>
            <Context.Provider value={ContextProps}>
                <Header />
                <div className="page-content">
                    <List />
                    <CreateDialog />
                </div>
            </Context.Provider>
        </Container>
    );
};

export default Component;
