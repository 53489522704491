import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';

// Context
import { AuthStore } from './context/authStore';

// @Pages: Essentials
import PersonalDataInventory from '../views/pages/personalDataInventory';
import ChooseYourPlan from '../views/pages/chooseYourPlan';
import Authenticate from '../views/pages/authentication';
import Dashboard from '../views/pages/dashboard';

// @Pages: Products
import Shareables from '../views/pages/shareables';

// @Pages: Modules
import GDPR_Essentials from '../views/pages/gdprEssentials';
import VirtualDPO from '../views/pages/virtualDataProtectionOfficer';
import Audits from '../views/pages/audits';
import ThirdParties from '../views/pages/thirdParties';

// @Pages: DPA
import DataProcessingAgreement from '../views/pages/dataProcessingAgreement';
import DPAListing from '../views/pages/dpaListing';

// @Pages: Workspaces
import WorkspaceUsers from '../views/pages/workspaceUsers';
import Workspaces from '../views/pages/workspaces';

// @Pages: Security Measures
import SecurityMeasuresCreate from '../views/pages/securityMeasuresCreate';
import SecurityMeasuresEdit from '../views/pages/securityMeasuresEdit';
import SecurityMeasures from '../views/pages/securityMeasures';

// @Pages: Settings
import PrivacyPortalSettings from '../views/pages/privacyPortalSettings';
import Settings from '../views/pages/settings';

// @Pages: Cookie Banner
import CookieBanner from '../views/pages/cookieBanner';

// @Pages: Deals
import DealsList from '../views/pages/deals/list';
import DealsView from '../views/pages/deals/view';

// @Pages: Modules
import Processes from '../views/pages/processes';
import Individuals from '../views/pages/individuals';

// Pages QA Module
import QA from '../views/pages/questions-and-answers';

// @Pages: Less of a priority
import UnsuccessfulPayment from '../views/pages/unsuccessfulPayment';
import RedeemCoupons from '../views/pages/redeemCoupons';
import VerifyCompany from '../views/pages/verify';
import NotFound from '../views/pages/notFound';
import SignUp from '../views/pages/signup';
import Onboarding from '../views/pages/onboarding';

const ExportingComponent = () => {
    const { account } = AuthStore();

    return (
        <React.Fragment>
            <Router>
                <WrapperRoutes>
                    <Public
                        key={0} // This is a bug fix. We need this or other-wise the useEffect in Private component won't be invoked.
                        acc={account}
                        exact
                        path={['/authenticate/:plan_type/:currency_type/:price_type/', '/authenticate']}
                        component={Authenticate}
                    />
                    <Public key={1} acc={account} exact path={['/mobile-signup']} component={SignUp} />
                    <Private key={2} acc={account} exact path="/" component={Dashboard} />
                    <Private key={3} acc={account} exact path="/choose-your-plan" component={ChooseYourPlan} />
                    <Private key={4} exact path={'/payment-unsuccessful'} component={UnsuccessfulPayment} />
                    <Private
                        acc={account}
                        exact
                        path={[`/settings/privacy-portal`, `/settings/privacy-portal/:tab`]}
                        component={PrivacyPortalSettings}
                        key={5}
                    />
                    <Private key={7} acc={account} exact path={['/cookie-banner', '/cookie-banner/:tab']} component={CookieBanner} />
                    <Private key={8} exact path={[`/settings`, `/settings/:tab`]} component={Settings} />
                    <Private
                        exact
                        initial
                        path={[
                            '/stages/gdpr-essentials',
                            '/stages/gdpr-essentials/:stage',
                            '/stages/gdpr-essentials/edit',
                            '/stages/gdpr-essentials/edit/:stage',
                        ]}
                        component={GDPR_Essentials}
                        key={9}
                    />
                    <Private acc={account} exact path="/data-inventory" component={PersonalDataInventory} key={10} />

                    <Private acc={account} exact path={[`/shareables`, `/shareables/:tab`]} component={Shareables} key={12} />
                    <Public acc={account} exact path="/verify/:company_id" component={VerifyCompany} key={13} />
                    <Private acc={account} exact path={[`/audits`, `/audits/:id`]} component={Audits} key={14} />

                    <Public acc={account} exact path={['/coupons/redeem']} component={RedeemCoupons} key={15} />
                    <Private
                        key={16}
                        acc={account}
                        exact
                        path={[`/data-processing-agreements`]}
                        component={DPAListing}
                        accessRequired={{
                            flag: 'dpa',
                            packageFeature: 'dpa',
                        }}
                    />
                    <Private
                        acc={account}
                        exact
                        path={[
                            `/data-processing-agreements/:id`,
                            `/data-processing-agreements/:id/:route`,
                            `/data-processing-agreements/:id/:route/:secondRoute`, // for dynamic sections.
                        ]}
                        component={DataProcessingAgreement}
                        accessRequired={{
                            flag: 'dpa',
                            packageFeature: 'dpa',
                        }}
                        key={17}
                    />
                    <Private
                        acc={account}
                        exact
                        path={'/security-measures'}
                        component={SecurityMeasures}
                        accessRequired={{
                            flag: 'securityMeasures',
                            packageFeature: 'securityMeasures',
                        }}
                        key={18}
                    />
                    <Private
                        acc={account}
                        exact
                        path={'/security-measures/create'}
                        component={SecurityMeasuresCreate}
                        accessRequired={{
                            flag: 'securityMeasures',
                            packageFeature: 'securityMeasures',
                        }}
                        key={19}
                    />
                    <Private
                        acc={account}
                        exact
                        path={'/security-measures/edit/:id'}
                        component={SecurityMeasuresEdit}
                        accessRequired={{
                            flag: 'securityMeasures',
                            packageFeature: 'securityMeasures',
                        }}
                        key={20}
                    />

                    {/* New workspaces v2 */}
                    <Private acc={account} exact path={'/workspaces'} component={Workspaces} key={21} />
                    <Private acc={account} exact path={'/workspaces/:id/users'} component={WorkspaceUsers} key={22} />

                    <Private
                        acc={account}
                        exact
                        path={[`/ai/virtual-dpo`]}
                        component={VirtualDPO}
                        accessRequired={{
                            flag: 'virtual-dpo',
                        }}
                        key={23}
                    />
                    <Private acc={account} exact path={[`/deals`]} component={DealsList} accessRequired={{ flag: 'deals' }} key={24} />
                    <Private acc={account} exact path={[`/deals/:id`]} component={DealsView} accessRequired={{ flag: 'deals' }} key={25} />

                    {/* Modules: Third Parties*/}
                    <Private
                        acc={account}
                        exact
                        path={'/third-parties'}
                        component={ThirdParties}
                        accessRequired={{ flag: 'modules' }}
                        key={26}
                    />

                    {/* Module: Q & A  */}
                    <Private
                        acc={account}
                        exact
                        path={'/questions-and-answers'}
                        component={QA}
                        accessRequired={{ flag: 'qaModule' }}
                        key={27}
                    />

                    {/* Modules */}
                    <Private acc={account} exact path={'/processes'} component={Processes} accessRequired={{ flag: 'modules' }} key={28} />
                    <Private
                        acc={account}
                        exact
                        path={'/individuals'}
                        component={Individuals}
                        accessRequired={{ flag: 'modules' }}
                        key={29}
                    />

                    {[`/onboarding/:viewId`, `/onboarding`].map((routePath, index) => (
                        <Private
                            acc={account}
                            exact
                            path={routePath}
                            component={Onboarding}
                            accessRequired={{ flag: 'onboarding' }}
                            key={30 + index}
                        />
                    ))}

                    <Route component={NotFound} />
                </WrapperRoutes>
            </Router>
        </React.Fragment>
    );
};

const WrapperRoutes = ({ children }) => {
    const location = useLocation();
    const { account, checkSessionTimedOut } = AuthStore();
    const [lastCheck, setLastCheck] = useState(new Date());

    useEffect(() => {
        if (window.isCypressTestEnvironment) return true; // @Bugfix: This is affecting cypress tests when running in bulk.
        let now = new Date();
        var difference = (now - lastCheck) / 1000;
        if (account && difference >= 10) {
            // Whenever the user changes his route we should quickly check that his session hasn't ended.
            // We check only once every 10 seconds at leasts.
            checkSessionTimedOut();
            setLastCheck(new Date());
        }
        // eslint-disable-next-line
    }, [location]);

    return <Switch>{children}</Switch>;
};

const Private = ({ accessRequired, ...props }) => {
    const [accessChecked, setAccessChecked] = useState(false);

    const history = useHistory();
    const { account, globalFlags, checkUserAccess } = AuthStore();
    const prod = process.env.NODE_ENV === 'production';

    // Simple variables needed.
    const packageFeatures = account && account.companyPackage ? account.companyPackage.features : null;
    const hasFlags = Object.values(globalFlags).find((c) => c === true);

    /**
     * We check if the user has access to this page by either a flag or through a package.
     */

    const checkAccessForPage = () => {
        // Check his access.
        let accessPassed = checkUserAccess({
            flag: accessRequired.flag || null,
            packageFeature: accessRequired.packageFeature || null,
        });

        if (accessPassed === false) {
            history.push('/');
        }

        setAccessChecked(true);
    };

    // When is first loading we must check..
    useEffect(() => {
        if (accessRequired && hasFlags && packageFeatures) {
            checkAccessForPage();
        }

        // eslint-disable-next-line
    }, []);

    // @Bugfix: sometimes they access the url directly therefore accesses have not enough time to load.
    useEffect(() => {
        // We have a responses available
        if (accessRequired && !accessChecked) {
            const hasFlags = Object.values(globalFlags).find((c) => c === true);
            const hasPackageFeatures = packageFeatures ? true : false;

            if (hasFlags && hasPackageFeatures) {
                checkAccessForPage();
            }
        }
        // eslint-disable-next-line
    }, [globalFlags, account]);

    // Account is still undefined
    if (account === undefined) return null;

    // Not logged in.
    if (account === null) {
        if (!localStorage.getItem('@preventRedirect')) {
            localStorage.setItem('@redirectTo', window.location.pathname);
        } else {
            localStorage.removeItem('@preventRedirect');
        }
        return <Redirect to="/authenticate" />;
    }

    // While waiting for the flag to load..
    if (accessRequired && accessChecked === false) return null;

    // They are allowed to access only this page while not completing GDPR Essentials.
    // @Reminder: The flag required part is so certain pages while testing can be accessed.

    const allowedRoutesEvenUnfinishedGel = [`/stages/gdpr-essentials`, `/workspaces`, '/onboarding'];
    const matchAllowed = allowedRoutesEvenUnfinishedGel.find((c) => (props.path.includes(c) ? true : false));

    // Wait for the flag to load.
    if (globalFlags.onboarding === null && account.hasCompletedGDPREssentials === false) return null; // Wait for flags to load.

    // Redirect onboarding to new webapp
    if (
        (globalFlags.redirectToNewOnboarding === true || globalFlags.dashboardOnboardingEntities) &&
        (window.location.href.includes('onboarding') || window.location.href.includes('stages'))
    ) {
        const redirectUrl = prod ? 'https://beta.privasee.io/onboarding/domains' : 'http://localhost:3001/onboarding/domains';
        window.location.href = redirectUrl;
    }

    // Tools Entity redirect
    if (globalFlags.dashboardOnboardingEntities && window.location.href.includes('third-parties')) {
        const redirectUrl = prod ? 'https://beta.privasee.io/third-parties' : 'http://localhost:3001/third-parties';
        window.location.href = redirectUrl;
    }

    // Processes Entity redirect
    if (globalFlags.dashboardOnboardingEntities && window.location.href.includes('processes')) {
        const redirectUrl = prod ? 'https://beta.privasee.io/processes' : 'http://localhost:3001/processes';
        window.location.href = redirectUrl;
    }

    // Processes Entity redirect
    if (globalFlags.dashboardOnboardingEntities && window.location.href.includes('individuals')) {
        const redirectUrl = prod ? 'https://beta.privasee.io/individuals' : 'http://localhost:3001/individuals';
        window.location.href = redirectUrl;
    }

    // Dashboard redirect
    if (globalFlags.dashboardOnboardingEntities && window.location.pathname === '/') {
        const redirectUrl = prod ? 'https://beta.privasee.io/' : 'http://localhost:3001/';
        window.location.href = redirectUrl;
    }

    if (!globalFlags.onboarding) {
        if (account.hasCompletedGDPREssentials === false && !matchAllowed) return <Redirect to="/stages/gdpr-essentials" />;
    } else {
        if (account.hasCompletedGDPREssentials === false && !matchAllowed) return <Redirect to="/onboarding" />;
    }

    return <Route {...props} />;
};

const Public = ({ ...props }) => <Route {...props} />;

export default ExportingComponent;
