import React from 'react';
import { PageContext } from '..';
import { Button } from '@mui/material';

const Component = () => {
    const { data, deleteDeal } = PageContext();

    const confirmDelete = async () => {
        window.showAlert('Confirmation', `Are you sure you want to delete this deal?`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    deleteDeal();
                },
            },
        ]);
    };
    return (
        <React.Fragment>
            <div className="page-header">
                <div className="details">
                    <div className="heading">Deal {data.name} </div>
                </div>
                <div className="right-side">
                    <Button variant="contained" onClick={confirmDelete}>
                        Delete
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
