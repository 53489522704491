import React, { useState, useEffect } from 'react';

// Components
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, InputAdornment } from '@mui/material';
import { makeLocalEndpointRequest } from '../../../../../utils/endpoints';
import { useHistory } from 'react-router-dom';
import { logError, trackUserInteraction } from '../../../../../utils/helpers';

const defaultData = {
    otherPartyName: ``,
    name: '',
    amount: null,
};

const Component = () => {
    const [show, setShow] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const history = useHistory();

    const [editData, setEditData] = useState({ ...defaultData });

    const onEventShow = () => {
        setShow(true);
        setEditData({ ...defaultData });
    };

    useEffect(() => {
        document.addEventListener(`dialog:create`, onEventShow);

        return () => {
            document.removeEventListener(`dialog:create`, onEventShow);
        };
    }, []);

    const onDismiss = () => {
        setShow(false);
        setEditData({ ...defaultData });
    };

    const onSubmit = async () => {
        try {
            setSubmitted(true);

            // Create deal
            const res = await makeLocalEndpointRequest(
                `/api/deals/createDeal`,
                {
                    payload: editData,
                },
                'POST',
            );

            // Track this..
            await trackUserInteraction(`Creates Deal`, {
                dealId: res.dealId,
                details: editData,
            });

            history.push(`/deals/${res.dealId}`);

            // Redirect to deal created
        } catch (err) {
            setSubmitted(false);
            trackUserInteraction(`Having Difficulties`, { reason: `failed to submit create deal` });
            await logError(`CREATE_DEAL`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    if (show === false) return null;

    return (
        <React.Fragment>
            <Dialog className={`edit-dpa-modal`} open={true} maxWidth={'sm'} fullWidth={true}>
                <DialogTitle data-cy="dialog-title">Create Deal</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="form-group" style={{ marginBottom: 24 }}>
                        <div className="label" style={{ marginBottom: 6 }}>
                            Name
                        </div>

                        <div className="input">
                            <TextField
                                autoFocus
                                id="tool-name"
                                disabled={submitted}
                                fullWidth
                                variant="standard"
                                placeholder="Deal Name"
                                value={editData.name}
                                onChange={(e) => setEditData({ ...editData, name: e.target.value })}
                                data-cy="dialog-input"
                            />
                        </div>
                    </div>
                    <div className="form-group" style={{ marginBottom: 24 }}>
                        <div className="label" style={{ marginBottom: 6 }}>
                            Other Party
                        </div>

                        <div className="input">
                            <TextField
                                autoFocus
                                id="tool-name"
                                disabled={submitted}
                                fullWidth
                                variant="standard"
                                placeholder="Company Name"
                                value={editData.otherPartyName}
                                onChange={(e) => setEditData({ ...editData, otherPartyName: e.target.value })}
                                data-cy="dialog-input"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label" style={{ marginBottom: 6 }}>
                            Amount <span style={{ color: 'rgba(255,255,255,0.8)' }}>(Optional)</span>
                        </div>

                        <div className="input">
                            <TextField
                                disabled={submitted}
                                fullWidth
                                type="number"
                                variant="standard"
                                placeholder="Amount"
                                value={editData.amount || ''}
                                onChange={(e) => setEditData({ ...editData, amount: e.target.value })}
                                data-cy="dialog-input"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" disabled={submitted} onClick={onDismiss} data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        disabled={submitted || !editData.name || !editData.otherPartyName}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        data-cy="dialog-submit-button"
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
