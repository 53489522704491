import React, { createContext, useContext, useEffect, useState } from 'react';
import lodash from 'lodash';

// Other things
import { checkBackendFlag, checkUserFlag, logError, trackUserInteraction, useStateRef } from '../../../utils/helpers';
import { AuthStore } from '../../../utils/context/authStore';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { decideBestPage, formatCookieObjects, updateUrl } from './utils';

// Components
import PageContainer from './components/pageContainer';
import API from './components/api';

// Pages
import Customization from './pages/customization';
import General from './pages/settings/general';
import PrivacyPolicy from './pages/settings/privacy';
import CookiePolicy from './pages/settings/cookies';
import AddToWebsite from './pages/addToWebsite';
import availableLanguages from '../../../dummy_data/availableLanguages.json';

// Mappings

export const PageMappings = {
    customization: (props) => <Customization {...props} />,
    general: (props) => <General {...props} />,
    'privacy-policy': (props) => <PrivacyPolicy {...props} />,
    'cookie-policy': (props) => <CookiePolicy {...props} />,
    'add-to-website': (props) => <AddToWebsite {...props} />,
};

// Context
const Context = createContext({});
export const ComponentState = () => useContext(Context);

const Settings = (props) => {
    const [tab, setTab] = useState(props.match.params.tab ? props.match.params.tab : 'customization');
    const [defaultData, setDefaultData] = useState(null);
    const [data, setData] = useState(null);
    const [specificRecommendations, setSpecificRecommendations] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [validationKeys, setValidationKeys] = useState({});
    const [changesDetected, setChangesDetected] = useState(false);
    const [implementationCheck, setImplementationCheck] = useState(null);
    const [dataInventory, setDataInventory] = useState(null);

    // Flags
    const [flags, setFlags] = useState({
        renderAllSpecialCategoryData: false,
        privacyPortalTemplates: false,
        californiaPolicy: false,
        listSubProcessors: false,
    });

    const [customerTranslations, setCustomerTranslations] = useStateRef({
        privacyPortal: {
            src: {},
            dist: {},
        },
    });

    const [languages, setLanguages] = useState({ privacyPortal: null });

    const { account } = AuthStore();

    const updateData = (path, val) => {
        setData((currentState) => {
            const objMod = Object.assign({}, currentState);
            lodash.set(objMod, path, val);
            return objMod;
        });
    };

    const loadFlags = async () => {
        try {
            const renderAllSpecialCategoryData = await checkBackendFlag('renderAllSpecialCategoryData', false, null, {
                _companyId: account._companyId,
            });
            const privacyPortalTemplates = await checkUserFlag(`privacyPortalTemplates`, false);
            const californiaPolicy = await checkUserFlag(`californiaPolicy`, false);
            const listSubProcessors = await checkUserFlag(`listSubProcessors`, false);

            // Get customer translations @TBD: To move somewhere else.
            const res = await makeEndpointRequest('GetCustomerTranslations', {
                systems: ['privacyPortal'],
            });

            if (res.length > 0) {
                setCustomerTranslations({
                    privacyPortal: {
                        src: res[0].src || {},
                        dist: res[0].dist || null,
                    },
                });
            }

            setFlags({
                ...flags,
                renderAllSpecialCategoryData,
                privacyPortalTemplates,
                californiaPolicy,
                customerTranslations,
                listSubProcessors,
            });
        } catch (err) {
            await logError(`FAILED_GET_COMPANY_PP_FLAGS`, err);
        }
    };

    const loadData = async () => {
        try {
            setLoading(true);
            let response = await makeEndpointRequest('getPrivacyPortal', {
                companyId: account._companyId,
            });
            setData({ ...response });
            setDefaultData(JSON.parse(JSON.stringify(response)));
            let res = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: response.thirdParties.map((x) => x.label),
                processes: [],
            });
            await loadFlags();

            // Check if it's been implemented right
            const { privacyPortal: PrivacyPortalCheck } = await makeEndpointRequest('GetImplementationsChecks');
            setImplementationCheck(PrivacyPortalCheck);

            setSpecificRecommendations(res);
            await trackUserInteraction(`Privacy Portal - Settings`);
            setLoading(false);
        } catch (err) {
            await logError(`LOAD_PRIVACY_SETTINGS`, err);
            await trackUserInteraction(`Having Difficulties`, { reason: `Failed to load privacy portal settings` });
            setError(true);
            setLoading(false);
        }
    };

    const saveChanges = async () => {
        try {
            setLoading(true);
            const pageRendered = decideBestPage(tab);
            const mappingIndex = Object.keys(PageMappings).findIndex((k) => k === pageRendered);
            if (mappingIndex === 0) {
                // Customization page
                // eslint-disable-next-line
                await makeEndpointRequest(`SaveCompanyInfo`, {
                    update: {
                        isPrivacyPortalHosted: data.isPrivacyPortalHosted, // Implementation
                        privacyPortalSettings: data.privacyPortalSettings, // includes switch and theme,
                        // And there's no need for Logo. That's self-sufficient.
                    },
                });
                if (account.ucSettingsId) {
                    // Not all users have it enabled by default.
                    await makeEndpointRequest(`SyncUCInventory`);
                }
            } else if (mappingIndex === 1) {
                // Settings => General
                await makeEndpointRequest(`SaveCompanyInfo`, {
                    update: {
                        privacyPortalSettings: data.privacyPortalSettings, // Switches
                        // Languages stuff
                        defaultLanguage: data.defaultLanguage,
                        languagesAvailable: data.languagesAvailable,
                    },
                });
            } else if (mappingIndex === 2) {
                // Settings => Privacy Policy
                const privacyFields = {
                    operateInEU: data.operateInEU,
                    operateInUK: data.operateInUK,
                    branchInEU: data.branchInEU,
                    branchInUK: data.branchInUK,
                    icoRegNumber: data.icoRegNumber,
                    branchEUAddress: data.branchEUAddress,
                    branchUKAddress: data.branchUKAddress,
                    ukRepInfo: data.ukRepInfo,
                    euRepInfo: data.euRepInfo,
                    automatedDecisionMaking: data.automatedDecisionMaking,
                };

                const fieldsHaveErrors = Object.keys(privacyFields).filter((x) => JSON.stringify(validationKeys).includes(x));

                if (fieldsHaveErrors.length < 1) {
                    await makeEndpointRequest('SaveCompanyInfo', { update: privacyFields });
                }

                if (JSON.stringify(data.dataElements) !== JSON.stringify(defaultData.dataElements)) {
                    // Called only if something is different.
                    await makeEndpointRequest('UpdateDataElementsBulk', {
                        entries: data.dataElements.map((elm) => {
                            const { _id, ...rest } = elm;
                            return {
                                _id,
                                update: rest,
                            };
                        }),
                    });
                }
            } else if (mappingIndex === 3) {
                // Settings => Cookie Policy
                await makeEndpointRequest('UpdateCookieType', {
                    update: {
                        functionality: formatCookieObjects(data.thirdPartyCookies.entries.filter((c) => c.type === 'Functional')),
                        necessary: formatCookieObjects(data.thirdPartyCookies.entries.filter((c) => c.type === 'Necessary')),
                        analytical: formatCookieObjects(data.thirdPartyCookies.entries.filter((c) => c.type === 'Analytical')),
                        targeting: formatCookieObjects(data.thirdPartyCookies.entries.filter((c) => c.type === 'Targeting')),
                    },
                });
                //API to Edit Cookie data
                await makeEndpointRequest('SaveCompanyCookieInformation', {
                    update: {
                        webBeaconsUse: data.thirdPartyCookies.useWebBeacons,
                    },
                });
                if (account.ucSettingsId) {
                    // Not all users have it enabled by default.
                    await makeEndpointRequest(`SyncUCInventory`);
                }
            }

            // eslint-disable-next-line
            setDefaultData(JSON.parse(JSON.stringify(data)));
            setLoading(false);

            let successMsg = `All the changes you've made have been saved.`;

            if (account.ucSettingsId && tab !== Object.keys(PageMappings)[1]) {
                successMsg += ` Please be aware that any changes related to the cookie banner (theme color, cookies) will take some time to be applied.`;
            }

            window.showAlert('Settings Saved', successMsg, `success`);
            setChangesDetected(false);
        } catch (err) {
            await logError(`SAVE_PRIVACY_SETTINGS`, err, { tab });
            await trackUserInteraction(`Having Difficulties`, {
                reason: `Failed to save the privacy settings`,
            });
            window.showAlert('Having Difficulties', `The changes you've made couldn't be saved. Please try again!`, `error`);
            setLoading(false);
        }
    };

    const saveContentBlocks = async () => {
        try {
            setLoading(true);
            await makeEndpointRequest(`SaveCompanyInfo`, {
                update: {
                    privacyPortalSettings: data.privacyPortalSettings,
                },
            });
            await makeEndpointRequest(`SetCustomerTranslation`, {
                payload: {
                    ...customerTranslations.privacyPortal.src,
                },
                system: 'privacyPortal',
            });
            setLoading(false);
            window.showAlert(
                'Content blocks Saved',
                `All the content blocks have been saved. Please be aware that it will take a few minutes to translate your content blocks.`,
                `success`,
            );
        } catch (err) {
            setLoading(false);
        }
    };

    const saveCustomerTranslations = async () => {
        try {
            setLoading(true);
            await makeEndpointRequest(`SetCustomerTranslation`, {
                payload: {
                    ...customerTranslations.privacyPortal.src,
                },
                system: 'privacyPortal',
            });
            setLoading(false);
            window.showAlert(
                'Translations Saved',
                `The changes you've made have been saved. Please wait 3-5 minutes for them to appear on your privacy portal.`,
                `success`,
            );
        } catch (err) {
            setLoading(false);
        }
    };

    const loadLanguages = async () => {
        try {
            // eslint-disable-next-line
            const res = await makeEndpointRequest('GetSystemTranslations', {
                systems: ['privacyPortal'],
                languages: data.languagesAvailable,
                payload: {}, // We don't pass company id to not include customer translations.
            });
            setLanguages(res);
        } catch (err) {
            await logError(`LOAD_LANGUAGES`, err);
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (defaultData !== null) {
            // avoid issues on boot
            setData(JSON.parse(JSON.stringify(defaultData)));
            setValidationKeys({});
        }
        updateUrl(tab);
        // eslint-disable-next-line
    }, [tab]);

    useEffect(() => {
        if (data !== null && languages.privacyPortal === null) {
            loadLanguages();
        }
        // eslint-disable-next-line
    }, [data]);

    const setTranslationField = (system, langKey, component, field, value) => {
        setCustomerTranslations((currentState) => {
            const newState = { ...currentState };
            lodash.set(newState, `${system}.src.${langKey}.${component}.${field}`, value);
            return newState;
        });
    };

    const deleteTranslationField = (system, component, field) => {
        setCustomerTranslations((currentState) => {
            const newState = { ...currentState };

            // Delete it from all languages.
            availableLanguages.forEach((lang) => {
                // Example: sometimes I want to delete an entire component not only a field.
                if (field) {
                    lodash.unset(newState, `${system}.src.${lang.code}.${component}.${field}`);
                } else {
                    lodash.unset(newState, `${system}.src.${lang.code}.${component}`);
                }
            });

            return newState;
        });
    };

    const getTranslationFiledValue = (system, langKey, component, field) => {
        const langDefaultValue =
            languages.privacyPortal !== null ? lodash.get(languages, `${system}.${langKey}.${component}.${field}`) : '';

        if (langKey === 'EN') {
            const customSourceLang = lodash.get(customerTranslations, `${system}.src.EN.${component}.${field}`);
            if (customSourceLang !== undefined) return customSourceLang;
        }

        // If it's a custom...
        if (langKey !== 'EN') {
            const customTranslatedDefault = lodash.get(customerTranslations, `${system}.dist.${langKey}.${component}.${field}`);
            const customSourceLang = lodash.get(customerTranslations, `${system}.src.${langKey}.${component}.${field}`);
            if (customSourceLang !== undefined) return customSourceLang;
            if (customTranslatedDefault !== undefined) return customTranslatedDefault;
        }

        return langDefaultValue;
    };

    const ComponentRendered = PageMappings[decideBestPage(tab)];

    const ContextProps = {
        // Tabs
        tab,
        setTab,
        // Loadings and errors..
        loading,
        setLoading,
        error,
        setError,
        // Data..
        data,
        setData,
        defaultData,
        setDefaultData,
        updateData,
        // Validation system
        validationKeys,
        setValidationKeys,
        // Changes..
        changesDetected,
        setChangesDetected,
        // Saving
        saveChanges,
        saveDisabled: loading || Object.keys(validationKeys).length > 0,
        // Needed right now
        specificRecommendations,
        setSpecificRecommendations,
        // Checks
        implementationCheck,
        setImplementationCheck,
        // Flags
        flags,
        // Translations
        languages,
        customerTranslations,
        setCustomerTranslations,
        setTranslationField,
        getTranslationFiledValue,
        deleteTranslationField,
        saveCustomerTranslations,
        saveContentBlocks,
        // Data Inventory
        dataInventory,
        setDataInventory,
    };

    return (
        <Context.Provider value={ContextProps}>
            <PageContainer>
                <ComponentRendered />
            </PageContainer>
            <API />
        </Context.Provider>
    );
};

export default Settings;
