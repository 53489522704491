import React from 'react';
import { Button } from '@mui/material';

const Component = () => {
    const onButtonPressed = () => {
        document.dispatchEvent(new CustomEvent('dialog:create'));
    };
    return (
        <React.Fragment>
            <div className="page-header">
                <div className="details">
                    <div className="heading">Deals</div>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" onClick={onButtonPressed}>
                        Create Deal
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
