import React from 'react';
import { PageContext } from '..';
import { formatNumber } from '../../../../../utils/helpers';

const Component = () => {
    const { data } = PageContext();

    return (
        <React.Fragment>
            <div className="component-details">
                <div className="heading">Details</div>
                <div className="entries">
                    <div className="entry">
                        <div className="label">Other Party</div>
                        <div className="value">{data.otherPartyName}</div>
                    </div>
                    <div className="entry">
                        <div className="label">Amount</div>
                        <div className="value">{data.amount ? `$${formatNumber(data.amount)}` : `Not specified.`}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
