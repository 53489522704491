import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Navigation from './navigation';
import Alerts from './alerts';

const LayoutContainer = (props) => {
    const pageClass = props.classNames || '';
    const isGuestLayout = props.guestLayout === true;
    const pageStyling = props.pageContainerStyling || {};

    const isLocalDevelopment = window.location.href.includes('localhost:3000');

    return (
        <React.Fragment>
            <Helmet>
                <title>Privasee - {props.title}</title>
                {!isLocalDevelopment && <script defer data-domain="privasee.io" src="https://plausible.io/js/plausible.js"></script>}
            </Helmet>
            <div className={`app-layout ${props.isAlternateBackground && 'isAlternateBackground'}`}>
                {!isGuestLayout && <Navigation />}
                {!isGuestLayout ? (
                    <div className={`layout-user ${props.fullWidth === true && 'full-width-page'}`}>
                        <div className="layout-wrapper-content">
                            <div className={`layout-page ${pageClass}`} style={pageStyling}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={pageStyling} className={`layout-guest ${pageClass}`}>
                        {props.children}
                    </div>
                )}
            </div>
            <Alerts />
        </React.Fragment>
    );
};

export default LayoutContainer;
