import React from 'react';
import { PageContext } from '..';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { formatNumber } from '../../../../../utils/helpers';

const Component = () => {
    const { data } = PageContext();
    const history = useHistory();

    const seeDeal = (id) => history.push(`/deals/${id}`);

    return (
        <div className="entries">
            {data.length < 1 && (
                <div className="no-entries">
                    <div className="content">
                        <div className="heading">No Deals</div>
                        <div className="description">You haven't created any deals.</div>
                    </div>
                </div>
            )}

            {data.map((entry, ix) => (
                <div key={ix} className={`entry`}>
                    <div className="content">
                        <div className="details">
                            {entry.name} {entry.amount ? `($${formatNumber(entry.amount)})` : ``}
                        </div>
                        <div className="right-side">
                            <Button variant="outlined" color="primary" onClick={() => seeDeal(entry._id)}>
                                See Deal
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Component;
