import React from 'react';

// // Context
import { PageContext } from '..';
import { Button, CircularProgress } from '@mui/material';

const Component = () => {
    const { dealId, loadingDocuments, loadDocuments, documents } = PageContext();

    const emailAddress = `dd+${dealId}@privasee.io`;

    const extractNumber = (fileName) => {
        const match = fileName.match(/^\d+\./);

        if (match) {
            const number = match[0].slice(0, -1);
            return parseInt(number, 10);
        }

        // Return a very low negative number to place files without numbers at the beginning
        return -9999;
    };

    const getFiles = () => {
        const arr = documents;

        arr.sort((a, b) => {
            const fileNameA = a.fileName.toLowerCase();
            const fileNameB = b.fileName.toLowerCase();

            // Extract and compare the numbers before the dot
            const numberA = extractNumber(fileNameA);
            const numberB = extractNumber(fileNameB);

            if (numberA < numberB) {
                return -1;
            }
            if (numberA > numberB) {
                return 1;
            }
            // If numbers are equal, compare the full file names
            return fileNameA.localeCompare(fileNameB);
        });

        return arr;
    };

    return (
        <React.Fragment>
            <div className="component-documents">
                <div className="header">
                    <div className="heading">Documents Attached</div>
                    <div className="right-side">
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => loadDocuments(true)}
                            disabled={loadingDocuments}
                        >
                            {loadingDocuments ? (
                                <React.Fragment>
                                    <CircularProgress size={18} style={{ marginRight: 6 }} /> Getting your documents..
                                </React.Fragment>
                            ) : (
                                <React.Fragment>Refresh</React.Fragment>
                            )}
                        </Button>
                    </div>
                </div>
                <div className="entries">
                    {documents.length < 1 && <div className="no-entries">There are no documents attached.</div>}
                    {getFiles().map((c, ix) => (
                        <div key={ix} className={`entry`} onClick={() => window.open(c.link, '_blank')}>
                            <div className="icon-file">
                                <i className="elm fa-regular fa-file"></i>
                            </div>
                            <div className="label">{c.fileName}</div>
                            <div className="options">
                                <div className="btn">
                                    <div className="icon">
                                        <i className="elm fa-regular fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer">
                    <div className="icon">
                        <i className="elm fa-regular fa-circle-info"></i>
                    </div>
                    <div className="text">
                        Send any conversations or documents related to this deal to:{' '}
                        <a href={`mailto:${emailAddress}`}>
                            <b>{emailAddress}</b>
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
