import React, { useState, createContext, useContext, useEffect, useRef } from 'react';
import { checkUserFlag, getAuth0Client, logError, trackUserInteraction, useStateRef } from '../helpers';
import { makeEndpointRequest } from '../endpoints';

// Components
import { LiveChatProvider } from '../helpers';
import { initializeHojar } from '../helpers';

const Context = createContext({});
export const AuthStore = () => useContext(Context);
let timerCheckSession = null;

const AppContext = (props) => {
    const [account, setAccount] = useState();
    const accountRef = useRef();
    const [hotjarSetup, setHotjarSetup] = useState(false);

    const [globalFlags, setGlobalFlags, globalFlagsRef] = useStateRef({
        audits: null,
        addProcessesToDPA: null,
        'virtual-dpo': null,
        missingSubProcessors: null,
        modules: null,
        qaModule: null,
        algoliaSearch: null,
        additionalCookieInformation: null,
        gelDecoupling: null,
        onboarding: null,
        // Access Flags
        dpa: null,
        securityMeasures: null,
        'redirect-to-new-onboarding': null,
    });

    const setReferrals = async () => {
        // Check referrer
        const referrer = document.referrer;

        await localStorage.removeItem('referrer');
        if (referrer) {
            await localStorage.setItem('referrer', referrer);
        }
    };

    useEffect(() => {
        accountRef.current = account;

        if (account) {
            loadGlobalFlags();
        }

        // Installing hotjar..
        if (hotjarSetup === false && account && hotjarSetup === false) {
            window.account = account; // making sure is set
            initializeHojar();
            setHotjarSetup(true);
        }
        // eslint-disable-next-line
    }, [account]);

    const getAuthenticationState = async () => {
        try {
            const res = await makeEndpointRequest('GetCurrentUser');
            const { data } = await makeEndpointRequest('GetUserAccountInformation');
            const userData = data.GetUserAccountInformation;
            const foundAccount = res.data.GetCurrentUser;
            foundAccount.registerDate = userData.registerDate;
            foundAccount.companyPackage = userData.companyPackage || {};
            foundAccount.hasCompletedGDPREssentials = userData.completedGDPREssentials;
            foundAccount.stripeCustomerId = userData.stripeData.stripeCustomerId;
            foundAccount.stripeSubscriptionId = userData.stripeData.stripeSubscriptionId;
            foundAccount.plan = userData.stripeData.plan;
            foundAccount.currency = userData.stripeData.currency;

            // V2..
            foundAccount.ucSettingsId = userData.usercentricsSettingsId;
            foundAccount.workspace = userData.workspace;

            setAccount({ ...foundAccount });
            return foundAccount;
        } catch (err) {
            setAccount(null);
            if (err.response && err.response.status !== 401) {
                await logError(`GET_CURRENT_USER`, err);

                // Fixing the state the user is in..
                const client = await getAuth0Client();
                await client.logout();
                setAccount(undefined);
            }
        }
    };

    const logOut = async (timedOut = false) => {
        await localStorage.setItem('@preventRedirect', true);
        if (timedOut === false) {
            // if is timed out there's no point in calling these endpoints.
            await makeEndpointRequest('Logout'); // THIS ALWAYS HAS TO BE CALLED FIRST
            const client = await getAuth0Client();
            await client.logout();
            window.location = `${window.location.origin}/authenticate`;
        }
        setAccount(null);
        if (timedOut === true) {
            window.showAlert(
                'Session timed out',
                `Your browser session has timed out. Please log in again to continue your journey on our application.`,
                `info`,
            );
        }
    };

    const onSessionTimeOut = async () => {
        await trackUserInteraction(`Session timed out`, { actionTaken: `user was logged out` });
        logOut(true);
    };

    const checkSessionTimedOut = async () => {
        try {
            if (!accountRef.current) return false;
            // eslint-disable-next-line
            await makeEndpointRequest('GetCurrentUser');
        } catch (err) {
            logOut(true);
        }
    };

    const loadGlobalFlags = async () => {
        try {
            // Is embedded portal? avoid loading flags.
            const isEmbeddedPortal = window.location.href.includes('/embedded-privacy-portal');
            if (isEmbeddedPortal) return false;

            // If is a cypress environment..
            if (window.isCypressTestEnvironment) {
                setGlobalFlags({
                    audits: false,
                    dpa: true,
                    modules: true, // is needed for our testing.
                    securityMeasures: true,
                    missingSubProcessors: true,
                    gelDecoupling: true, // Needed for cookie banner testing.
                });
                return false;
            }

            const audits = await checkUserFlag('audits', false);
            const addProcessesToDPA = await checkUserFlag('addProcessesToDPA', false);
            const virtualDPO = await checkUserFlag('virtual-dpo', false);
            const deals = await checkUserFlag('deals', false);
            const missingSubProcessors = await checkUserFlag('missingSubProcessors', false);
            const modules = await checkUserFlag(`modules`, false);
            const qaModule = await checkUserFlag(`qa-module`, false);
            const gelDecoupling = await checkUserFlag(`gelDecoupling`, false);
            const onboarding = await checkUserFlag(`onboarding`, false);
            const requirements = await checkUserFlag(`sidenav-requirements`, false);
            const aiAssistant = await checkUserFlag(`ai-assistant`, false);
            const aiAssistantScaleable = await checkUserFlag(`ai-assistant-scaleable`, false);
            const thirdPartiesBeta = await checkUserFlag(`third-parties-beta`, false);
            const redirectToNewOnboarding = await checkUserFlag(`redirect-to-new-onboarding`, false);

            // Access Flags
            const dpa = await checkUserFlag('dpa', false);
            const securityMeasures = await checkUserFlag('securityMeasures', false);
            const dashboardOnboardingEntities = await checkUserFlag('dashboard-onboarding-entities', false);

            setGlobalFlags((currentState) => ({
                // Existing flags
                ...currentState,
                // New flags
                dpa,
                audits,
                securityMeasures,
                addProcessesToDPA,
                'virtual-dpo': virtualDPO,
                missingSubProcessors,
                deals,
                modules,
                qaModule,
                gelDecoupling,
                onboarding,
                requirements,
                aiAssistant,
                aiAssistantScaleable,
                thirdPartiesBeta,
                redirectToNewOnboarding,
                // Access Flags
                dpa,
                securityMeasures,
                dashboardOnboardingEntities,
            }));

            // Load the next flags.
            loadSecondaryGlobalFlags();
        } catch (err) {
            await logError(`LOAD_GLOBAL_FLAGS`, err);
        }
    };

    /**
     *
     * These flags are of a lower priority and they are not gonna block the rendering of the app if they're not loaded yet.
     */

    const loadSecondaryGlobalFlags = async () => {
        try {
            const additionalCookieInformation = await checkUserFlag('additionalCookieInformation', false);
            const algoliaSearch = await checkUserFlag(`algolia-search`, false);

            setGlobalFlags((currentState) => ({
                ...currentState,
                additionalCookieInformation,
                algoliaSearch,
            }));
        } catch (err) {
            await logError(`loadSecondaryGlobalFlags`, err);
        }
    };

    /**
     * This function will check if the current user has access to a specific flag or packageFeature by checking the company features and/or global Flags.
     * Example: If they have the flag but not the package feature they will still have access.
     * @param {*} params { flag, "packageFeature" }
     * @returns
     */

    const checkUserAccess = (params) => {
        try {
            // simple checks first.
            const packageFeatures = account && account.companyPackage ? account.companyPackage.features : null;
            const hasFlags = Object.values(globalFlags).find((c) => c === true);

            // We cannot check if one of those are missing.
            if (!packageFeatures || !hasFlags) return false;

            let accessPassed = false;

            if (params.flag && globalFlags[params.flag]) {
                accessPassed = true;

                // // eslint-disable-next-line
                // console.log('bingo flag', { params, account, globalFlags });
            }

            if (params.packageFeature && packageFeatures[params.packageFeature]) {
                accessPassed = true;

                // // eslint-disable-next-line
                // console.log('bingo feature', { params, account, globalFlags });
            }

            return accessPassed;
        } catch (err) {
            logError(`checkUserAccess`, err, { params, account, globalFlags });
            return false;
        }
    };

    useEffect(() => {
        setReferrals();
        getAuthenticationState();
        document.addEventListener('onSessionTimeOut', onSessionTimeOut);
        document.addEventListener('refreshAuthenticationState', getAuthenticationState);
        timerCheckSession = setInterval(checkSessionTimedOut, 1800000); // 30 Minutes in miliseconds
        return () => {
            document.removeEventListener('onSessionTimeOut', onSessionTimeOut);
            document.removeEventListener('refreshAuthenticationState', getAuthenticationState);
            clearInterval(timerCheckSession);
        };
        // eslint-disable-next-line
    }, []);

    // Do not touch this. Is needed.
    window.account = account;

    const passedVariables = {
        account,
        accountRef,
        setAccount,
        getAuthenticationState,
        checkSessionTimedOut,
        logOut,
        globalFlags,
        globalFlagsRef,
        checkUserAccess,
    };

    return (
        <Context.Provider value={passedVariables}>
            <React.Fragment>{props.children}</React.Fragment>
            <LiveChatProvider account={account} />
        </Context.Provider>
    );
};

export default AppContext;
